import { Facebook, Instagram, Linkedin, Twitter } from "lucide-react";
import remedia_logo from "../assest/remediaLogo.svg";

const Footer = () => {
  return (
    <div className="bg-white px-[15px] md:px-[56px] flex justify-between relative z-50 py-2">
      <img src={remedia_logo} alt="remedia logo" className="w-[100px]"/>
      <div className="flex flex-col gap-1">
        <div>Contact us</div>
        <a href="contact@remediaedu.com" className="hover:underline text-blue-500">contact@remediaedu.com</a>
        <div className="flex gap-2">
            <a href="/#"><Twitter size={20} className="text-secondary"/></a>
            <a href="/#"><Facebook size={20} className="text-secondary"/></a>
            <a href="/#"><Instagram size={20} className="text-secondary"/></a>
            <a href="/#"><Linkedin size={20} className="text-secondary"/></a>
        </div>
      </div>
    </div>
  )
}

export default Footer
