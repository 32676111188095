import student_submission from "../assest/studentSubmission.png";

const StudentSubmission = () => {
  return (
    <div className="relative">
        <div id="ellipse3" className="md:right-[0%] xl:right-[-12%]"></div>
        <div className="flex flex-col md:flex-row justify-center items-center md:gap-[6.5rem] mt-[25px] md:mt-[107px] lg:mt-[119px] px-[19px] lg:px-[43px] py-[10px]">
        <h1 className="text-[15px] font-roboto font-bold mb-[12px] inline-block md:hidden ">
            Tailored Student Guidance
        </h1>
        <div className="z-50 relative basis-3/5">
            <img src={student_submission} alt="student submission" />
        </div>
        <div className="z-50 basis-2/5">
            <h1 className="text-[28px] font-roboto font-bold mb-2 hidden md:inline">
            Tailored Student Guidance
            </h1>
            <p className="text-[9.06px] md:text-[15.27px] md:leading-[28.37px] font-workSans font-medium text-secondaryTextColor mt-[12px]">
            Remedia provides personalized, real-time guidance that helps students
            improve independently. By encouraging critical thinking and
            problem-solving, our platform supports students in becoming better,
            more independent learners.
            </p>
        </div>
        </div>
    </div>
  );
};

export default StudentSubmission;
