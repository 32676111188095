import React, {useState} from 'react';
import remedia_logo from "../assest/remediaLogo.svg";
import laptop from "../assest/laptop.png";
import { Mail } from "lucide-react";
import axios from 'axios';
import {  toast } from 'react-toastify';
const Hero = () => {
  const [email, setEmail] = useState("")

  const sendEmail = async () => {
    console.log(process.env.REACT_APP_BREVO_SECRET)
    const data = {
      sender: {
        name: "Omar Sabounji",
        email: "team@remedia.dev",
      },
      to: [
        {
          email: "omarsabounji3@gmail.com",
          name: "Omar Sabounji",
        },
      ],
      subject: "Pre-inscription",
      htmlContent: "<html><head></head><body><p>Hello Mr Omar,</p><p>email from website pre-inscription: "+email+" </p></body></html>",
    };
    try {
      const response = await axios.post('https://api.brevo.com/v3/smtp/email', data, {
        headers: {
          accept: 'application/json',
          'api-key': process.env.REACT_APP_BREVO_SECRET, // Replace with your actual API key
          'content-type': 'application/json',
        },
      });
      console.log('Email sent successfully:', response.data);
      toast("Successfully submitted! Thank you for joining.");
      setEmail("")
    } catch (error) {
      toast("Error contact the support. at contact@remediaedu.com");
      console.error('Error sending email:', error.response ? error.response.data : error.message);
    }
  };


  const handleClick = ()=>{
    sendEmail()

  }
  return (
    <div className="flex flex-col px-[19px] lg:px-[43px]  py-[10px] relative">
      <div id="ellipse1" className="md:right-[0%] xl:right-[-22%]"></div>
      <img
        src={remedia_logo}
        alt="remedia logo"
        className="self-center w-[148px] z-50 mb-[60px]"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-[28px] lg:gap-0 " id={"join-us"}>
        <div className="z-50 order-last md:order-first">
          <h1 className="text-[32px] md:text-[35px] lg:text-[40px] font-roboto leading-[49.78px] lg:leading-[61.78px] font-bold">
            Empowering <span className="text-main">Teachers</span>,
          </h1>
          <h1 className="text-[32px] md:text-[35px] lg:text-[40px] font-roboto leading-[49.78px] lg:leading-[61.78px] font-bold">
            Elevating <span className="text-main">Student</span> Learning with
            Tailored Assessments
          </h1>
          <p className="text-[13.7px] md:text-[18px] leading-[28.37px] font-workSans text-justify font-normal text-secondaryTextColor">
            Remedia’s innovative co-instructing platform is looking for educators to participate in our structured testing program. This program involves multiple phases where we ask for feedback on key features as we refine the platform to meet your classroom needs. Help shape the future of education by joining our testing program
          </p>
          <div className="flex flex-row gap-3 mt-[19px]">
            <div className="py-[4.84px] px-[8px] md:px-[18.16px] rounded-lg bg-white h-[48px] basis-[110.333333%] md:basis-4/5 lg:basis-[63%] text-left flex gap-2 items-center">
              <Mail strokeWidth={1.5} className="text-gray-500"/> 
              <input 
                type="email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                placeholder="Join our Testing Program"
                className="outline-none w-full"
              />
            </div> 
            <button onClick={handleClick} className="py-[4.84px] px-[18.16px] rounded-lg bg-secondary hover:bg-secondaryOnHover basis-5/12 md:basis-1/5 lg:basis-1/4 text-main font-bold h-[48px]">
              Sign-up
            </button>
          </div>
        </div>
        <div className="z-50">
          <img src={laptop} alt="laptop" />
        </div>
      </div>
      <div id="ellipse2"></div>
    </div>
  );
};

export default Hero;
