import security_img from "../assest/security.svg";

const PrivacyAndSecurity = () => {
  return (
    <div className="mt-[25px] md:mt-[75px] lg:mt-[98px] px-[19px] lg:px-[43px]">
        <div className="flex flex-col md:flex-row justify-center items-center md:gap-[6.5rem] mt-[25px] px-[19px] lg:px-[43px]  py-[20.79px] md:py-[99px] lg:py-[118px] bg-main">
            <div className="z-50 relative basis-3/5 md:order-last">
                <img src={security_img} alt="feedbacks" className="w-full" />
            </div>
            <div className="z-50 basis-2/5">
                <h1 className="text-[24.01px] lg:text-[48px] text-center md:text-left font-roboto font-bold mb-2 text-white">
                    Data privacy and security
                </h1>
                <p className="text-[9.06px] text-[#cdb5b5] md:text-[15.27px] md:leading-[28.37px] font-normal font-workSans mt-[12px]">
                    Remedia is dedicated to protecting student data and ensuring privacy
                    at every step. We are actively working to align with industry-leading
                    privacy standards and current best practices.
                </p>
            </div>
        </div>
    </div>
  );
};

export default PrivacyAndSecurity;
