import React from 'react'
// commits changes
const Community = () => {
  return (
    <div className='py-[29px] px-[20px] md:px-[100px] lg:py-[58px] lg:px-[250px] relative'>
        <div className='flex flex-col items-center gap-[58px]'>
            <h1 className='text-[18px] md:text-[32px] font-roboto font-bold lg:leading-[44px] text-center'>Join a growing community of educators who are transforming their classrooms with Remedia</h1>
            <div className='z-50 flex flex-col gap-[20px]'>
                <div className='py-[33px] px-[43px] bg-white rounded-lg'>
                    <div className='text-[21px] font-light leading-[32px] text-[#161C2D]'>“Remedia has streamlined how I assess my students' writing. The insights provided help me give targeted feedback without overwhelming my schedule.”</div>
                    <span className='text-[17px] font-normal font-roboto text-main'>— Sarah, 7th Grade English Teacher</span>
                </div>
                <div className='py-[33px] px-[43px] bg-white rounded-lg'>
                    <div className='text-[21px] font-light leading-[32px] text-[#161C2D]'>"Even in the testing phase, Remedia has been a game-changer in how I provide feedback to students. It allows me to focus on guiding them, not just grading."</div>
                    <span className='text-[17px] font-normal font-roboto text-main'>— Esther, Grade 10 History Teacher</span>
                </div>
                <div className='py-[33px] px-[43px] bg-white rounded-lg'>
                    <div className='text-[21px] font-light leading-[32px] text-[#161C2D]'>“I love how the platform keeps me in control, from designing assessments to giving personalized feedback. It feels like a tool made for teachers.”</div>
                    <span className='text-[17px] font-normal font-roboto text-main'>— Emily, 10th Grade ELA Teacher</span>
                </div>
            </div>
        </div>
        <div id="ellipse4" className="right-[2%] lg:right-[-29%] top-[21rem] lg:top-[47%]"></div>
    </div>
  )
}

export default Community
