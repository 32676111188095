import feedback_img from "../assest/feedback.png";

const Feedback = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center md:gap-10 mt-[25px] md:mt-[107px] xl:mt-[190px] px-[19px] lg:px-[43px] py-[10px]">
      <h1 className="text-[15px] font-roboto font-bold mb-[12px] inline-block md:hidden ">
        Comprehensive and In-Depth Feedback
      </h1>
      <div className="z-50 relative basis-3/5">
        <img src={feedback_img} alt="feedbacks"/>
      </div>
      <div className="z-50 basis-2/5">
        <h1 className="text-[28px] font-roboto font-bold mb-2 hidden md:inline">
            Comprehensive and In-Depth Feedback
        </h1>
        <p className="text-[9.06px] md:text-[16px] md:leading-[28.37px] font-workSans font-medium text-secondaryTextColor mt-[12px]">
          Remedia blends feature-based and rubric-based assessments to deliver
          comprehensive, actionable feedback. Our system adapts to student
          needs, providing teachers with deeper insights into student
          performance across multiple areas.
        </p>
      </div>
    </div>
  );
};

export default Feedback;
