
const JoinOurTesting = () => {
  return (
    <div className="flex gap-2 bg-secondary py-3 md:py-4 lg:py-[30px] mt-[60px] lg:mt-[119px] items-center justify-center">
      <span className="font-normal text-[10.06px] md:text-[17px] lg:text-[22px] leading-[44px] font-roboto text-white">Join Our Testing Program and Make a Difference!</span>
      <a href={"#join-us"}  className="bg-white text-[8px] lg:text-[17px] py-[9px] px-[25px] rounded-lg font-bold">Test It Out!</a>
    </div>
  )
}

export default JoinOurTesting
