import Community from "./sections/Community";
import Feedback from "./sections/Feedback";
import Footer from "./sections/Footer";
import Hero from "./sections/Hero";
import JoinOurTesting from "./sections/JoinOurTesting";
import PrivacyAndSecurity from "./sections/PrivacyAndSecurity";
import RemediaFeatures from "./sections/RemediaFeatures";
import Rubric from "./sections/Rubric";
import StudentSubmission from "./sections/StudentSubmission";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <main className="lg:overflow-hidden">
        <ToastContainer
            position="top-center"
            autoClose={5000}
            theme="light"
            type="succes"
            hideProgressBar={true}

        />
        <Hero />
     <Feedback />
     <Rubric />
     <StudentSubmission />
     <RemediaFeatures />
     <PrivacyAndSecurity />
     <JoinOurTesting />
     <Community />
     <Footer />
    </main>
  );
}

export default App;
