import rubric_img from "../assest/rubric.png";

const Rubric = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center md:gap-[6.5rem] mt-[25px] md:mt-[75px] lg:mt-[98px] px-[19px] lg:px-[43px] py-[10px]">
      <h1 className="text-[15px] font-roboto font-bold mb-[12px] inline-block md:hidden ">
        Teacher as the Game Master
      </h1>
      <div className="z-50 relative basis-3/5 md:order-last">
        <img src={rubric_img} alt="feedbacks" className="w-full"/>
      </div>
      <div className="z-50 basis-2/5">
        <h1 className="text-[28px] font-roboto font-bold mb-2 hidden md:inline">
          Teacher as the Game Master
        </h1>
        <p className="text-[9.06px] md:text-[15.27px] md:leading-[28.37px] font-workSans font-medium text-secondaryTextColor mt-[12px]">
          From designing assessments to guiding student learning and providing
          personalized feedback, Remedia ensures that teachers remain in full
          control. Our AI enhances your expertise, giving you the flexibility to
          adjust and refine every element.
        </p>
      </div>

    </div>
  );
};

export default Rubric;
