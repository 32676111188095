import remedia_features from "../assest/remediaFeatures.svg";

const RemediaFeatures = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center md:gap-[6.5rem] mt-[25px] md:mt-[75px] lg:mt-[98px] px-[19px] lg:px-[43px] py-[10px]">
      <h1 className="text-[15px] font-roboto font-bold mb-[12px] inline-block md:hidden ">
        Upholding Academic Integrity
      </h1>
      <div className="z-50 relative basis-3/5 md:order-last">
        <img src={remedia_features} alt="feedbacks" className="w-full" />
      </div>
      <div className="z-50 basis-2/5">
        <h1 className="text-[28px] font-roboto font-bold mb-2 hidden md:inline">
          Upholding Academic Integrity
        </h1>
        <p className="text-[9.06px] md:text-[15.27px] md:leading-[28.37px] font-workSans font-medium text-secondaryTextColor mt-[12px]">
          Our platform restricts AI-generated content and integrates advanced
          tools to limit plagiarism, ensuring that student work is authentic and
          ethical.
        </p>
      </div>
    </div>
  );
};

export default RemediaFeatures;
